<template>
  <div class="osahan-signup login-page">
    <video loop="" autoplay="" muted="" id="vid">
      <source src="img/bg.mp4" type="video/mp4" />
      <source src="img/bg.mp4" type="video/ogg" />
      Your browser does not support the video tag.
    </video>
    <div
      style="margin-top: -68px"
      class="
        d-flex
        align-items-center
        justify-content-center
        flex-column
        vh-100
      "
    >
      <div class="px-5 col-md-6 ml-auto">
        <div class="px-5 col-10 mx-auto">
          <h2>Change Password</h2>
          <p>Enter your email address, old password and new password below.</p>
          <form class="mt-5 mb-4">
            <div class="form-group">
              <label for="exampleInputEmail1">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputOldPassword1" class="text-dark"
                >Old Password</label
              >
              <input
                required
                v-model="oldPassword"
                type="password"
                pattern=".{6,}"
                placeholder="Enter Old Password"
                class="form-control"
                id="exampleInputOldPassword1"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputNewPassword1" class="text-dark"
                >New Password</label
              >
              <input
                required
                v-model="newPassword"
                type="password"
                pattern=".{6,}"
                placeholder="Enter New Password"
                class="form-control"
                id="exampleInputNewPassword1"
              />
            </div>
            <a v-on:click="sendPass" class="btn btn-primary btn-lg btn-block"
              >Save Changes</a
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: null,
      oldPassword: null,
      newPassword: null,
    };
  },
  methods: {
    sendPass() {
      let url = `${
        this.$store.state.baseURL
      }/api/profile/update/password?api_token=${
        JSON.parse(this.$store.state.curUser).token
      }&email=${this.email}&password=${this.oldPassword}&new_password=${
        this.newPassword
      }`;
      let request = new Request(url, { method: "POST" });
      fetch(request)
        .then((r) => {
          if (r.ok) {
            return r.json();
          }
        })
        .then((r) => {
          if (r.response) {
            console.log(r.data);
            window.localStorage.setItem('curUser',JSON.stringify(r.data))
            window.localStorage.setItem('jwt',r.data.token)
            this.$store.dispatch('getUser')
            this.$router.push("success_save");
          } else {
            if (r.message) alert(r.message);
            if (r.messages) alert(r.messages);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>